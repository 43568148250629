import GradientBar from "../gradient-bar/GradientBar";
import HorizontalDivider from "../horizontal-divider/HorizontalDivider";
import logo from "../../../assets/images/neutr-logo-big.svg";
import menu_icon from "../../../assets/images/menu_mobile.svg";
import "./Header.css";
import { useContext } from "react";
import { AppContext } from "../../../context/context-provider";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import LanguageSelector from "../language/LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = () => {
  const navigate = useNavigate();
  const handleNavClick = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  return (
    <div id="header-container">
      <header id="header">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <div className="mobile-nav">
          <HeaderMobileNav />
        </div>

        <nav className="full-nav">
          <a onClick={() => handleNavClick("/")}>{t("header_home")}</a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/credentials")}>
            {t("header_projects")}
          </a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/services")}>
            {t("header_services")}
          </a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/about-us")}>
            {t("header_about_us")}
          </a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/contact")}>
            {t("header_contact")}
          </a>
          <HorizontalDivider />
          <LanguageSelector />
        </nav>
      </header>
      <GradientBar />
    </div>
  );
};

const HeaderMobileNav = () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage, displayFloatinMenu, shouldDisplayFloatingMenu } =
    useContext(AppContext);
  const options = [
    {
      text: t("header_home"),
      url: "/",
    },
    {
      text: t("header_projects"),
      url: "/credentials",
    },
    {
      text: t("header_services"),
      url: "/services",
    },
    {
      text: t("header_about_us"),
      url: "/about-us",
    },
    {
      text: t("header_contact"),
      url: "/contact",
    },
  ];

  const handleButtonClick = () => {
    shouldDisplayFloatingMenu(!displayFloatinMenu);
  };

  const handleOptionSelected = (option) => {
    shouldDisplayFloatingMenu(false);
    window.location.href = option.url;
  };

  const handleChangeLanguage = (language) => {
    window.location.reload();
    changeLanguage(language);
  };

  const newLanguage = i18n.language.startsWith("es") ? "en" : "es";

  return (
    <div id="nav-button-container">
      <img src={menu_icon} alt="menu" onClick={handleButtonClick} />
      <div
        id="nav-button-options"
        className={displayFloatinMenu ? "display" : ""}
      >
        {options.map((option, index) => (
          <NavOption
            key={"select" + index}
            option={option}
            selected={false}
            onOptionSelected={handleOptionSelected}
          />
        ))}
        <NavOption
          key={"select" + -1}
          option={{
            text: t(`language_selector_${newLanguage}`),
          }}
          index={-1}
          selected={false}
          onOptionSelected={() => handleChangeLanguage(newLanguage)}
        />
      </div>
    </div>
  );
};

const NavOption = ({ option, selected, onOptionSelected }) => {
  const handleOptionClick = () => {
    onOptionSelected(option);
  };

  return (
    <div
      className={`nav-option ${selected === option ? "selected" : ""}`}
      onClick={handleOptionClick}
    >
      {option.text}
    </div>
  );
};

export default Header;
