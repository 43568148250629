import "./CredentialsPage.css";

import Credential from "../../components/credentials/credential/Credential";
import Button from "../../components/shared/button/Button";
import SectionTitle from "../../components/shared/section-title/SectionTitle";

import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/context-provider";
import SelectButton from "../../components/shared/select-button/SelectButton";
import { useLocation, useParams } from "react-router-dom";
import { t } from "i18next";
import useScrollPosition from "../../hooks/useScrollPosition";

const CredentialsPage = () => {
  const isScrolled = useScrollPosition();
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  // Get the userId param from the URL.
  let { credentialId } = useParams();
  const [ignoreCredentialId, setIgnoreCredentialId] = useState(false); //once the initial scroll has been done, ignore the credentialId
  const creadetianlRefForId = useRef(null);

  const { clients, credentials } = useContext(AppContext);

  //Filter
  const [filteredCredentials, setFilteredCredentials] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  //Active credential
  const [selectedCredential, setSelectedCredential] = useState(null);

  useEffect(() => {
    doFilter(selectedCategory);
  }, [credentials]);

  const scrollToTarget = () => {
    creadetianlRefForId.current.scrollIntoView();
  };

  useEffect(() => {
    if (!ignoreCredentialId && creadetianlRefForId.current) {
      scrollToTarget();
      setIgnoreCredentialId(true);
    }
  }, [creadetianlRefForId.current]);

  // Trigger the scroll on initial page load
  useEffect(() => {
    if (!ignoreCredentialId && credentialId) {
      credentials.forEach((credential) => {
        if (credential.id === credentialId) {
          setSelectedCredential(credential);
        }
      });
    }
  }, [credentialId]);

  const handleCredentialSelected = (credential) => {
    setSelectedCredential(credential);
  };

  const handleFilterSelected = (filterCategory) => {
    setSelectedCredential(null);
    setSelectedClient(null);
    if (filterCategory.id === null) {
      doFilter(null, null);
      setSelectedCategory(null);
    } else {
      const finalFilter =
        filterCategory.id === selectedCategory?.id ? null : filterCategory;
      doFilter(finalFilter, null);
      setSelectedCategory(finalFilter);
    }
  };

  const handleClientSelected = (client) => {
    setSelectedCredential(null);
    setSelectedCategory(null);

    if (client.id === null) {
      doFilter(null, null);
      setSelectedClient(null);
    } else {
      const finalFilter = client === selectedClient ? null : client;
      doFilter(null, finalFilter);
      setSelectedClient(finalFilter);
    }
  };

  const doFilter = (categoryFilter, clientFilter) => {
    let filtered = credentials;

    if (categoryFilter !== null) {
      filtered = filtered.filter((credential) => {
        return credential.categoriesId.indexOf(categoryFilter.id) !== -1;
      });
    }

    if (clientFilter !== null && clientFilter !== undefined) {
      filtered = filtered.filter((credential) => {
        return credential.clientId === clientFilter.id;
      });
    }

    filtered.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setFilteredCredentials(filtered);
  };

  const services = [
    {
      id: "lssU9UYnWNh1qki6umo4",
      name: t("services_audio_visual"),
    },
    {
      id: "kExzFukWYi5grU0mdbTW",
      name: t("services_advertising"),
    },
    {
      id: "z4rGOigp62CWw8yYWKBl",
      name: t("services_museums"),
    },
  ];

  return (
    <div id="credentials-page" className={isScrolled ? "scrolled" : ""}>
      <div
        className={`credentials-fixed ${
          selectedCredential ? "hide-fixed" : ""
        }`}
      >
        <div id="credentials-section-title-wrapper">
          <SectionTitle
            subtitle={t("credentials_title")}
            title={t("credentials_subtitle")}
          />
        </div>
        <CredentialsFilter
          clients={clients}
          services={services}
          selectedClient={selectedClient}
          selectedCategory={selectedCategory}
          isScrolled={isScrolled}
          onFilterSelected={handleFilterSelected}
          onClientSelected={handleClientSelected}
        />
        <CredentialsFilterMobile
          clients={clients}
          services={services}
          selectedClient={selectedClient}
          selectedCategory={selectedCategory}
          onFilterSelected={handleFilterSelected}
          onClientSelected={handleClientSelected}
        />
      </div>
      <div className="credentials">
        {filteredCredentials.map((credential, index) => {
          return (
            <div
              ref={credential.id === credentialId ? creadetianlRefForId : null}
              key={"cre-parent" + index}
            >
              <Credential
                credential={credential}
                someCredentialSelected={selectedCredential != null}
                isSelected={
                  selectedCredential != null &&
                  selectedCredential.id === credential.id
                }
                key={"cre" + index}
                onCredentialSelected={handleCredentialSelected}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CredentialsFilter = ({
  clients,
  services,
  selectedClient,
  selectedCategory,
  isScrolled,
  onFilterSelected,
  onClientSelected,
}) => {
  const formatClient = (client) => {
    if (client === null || client === undefined) {
      return t("credentials_client_filter");
    }
    if (client.name.length < 10) {
      return client.name;
    }
    return client.name.substring(0, 10) + "...";
  };

  return (
    <div id="credentials-filter" className={isScrolled ? "scrolled" : ""}>
      <div className="filter-buttons">
        <div className="filter-buttons-inner">
          <Button
            text={services[0].name}
            isSmall={false}
            isInverted={selectedCategory?.id === services[0].id}
            onClick={() => onFilterSelected(services[0])}
            width={2}
          />
          <Button
            text={services[1].name}
            isSmall={false}
            isInverted={selectedCategory?.id === services[1].id}
            onClick={() => onFilterSelected(services[1])}
            width={2}
          />
          <Button
            text={services[2].name}
            isSmall={false}
            isInverted={selectedCategory?.id === services[2].id}
            onClick={() => onFilterSelected(services[2])}
            width={2}
          />
        </div>
        <SelectButton
          selectdId="selectClients"
          text={formatClient(selectedClient)}
          isSmall={false}
          width={2}
          options={[
            { id: null, name: t("credentials_client_filter_all") },
            ...clients.sort((a, b) => a.name.localeCompare(b.name)),
          ]}
          setSelected={onClientSelected}
        />
      </div>
    </div>
  );
};
const CredentialsFilterMobile = ({
  clients,
  services,
  selectedClient,
  selectedCategory,
  onFilterSelected,
  onClientSelected,
}) => {
  const formatService = (selectedService) => {
    if (selectedService === null || selectedService === undefined) {
      return t("credentials_services_title");
    }

    if (selectedService.name.length < 10) {
      return selectedService.name;
    }
    return selectedService.name.substring(0, 10) + "...";
  };

  const formatClient = (client) => {
    if (client === null || client === undefined) {
      return t("credentials_client_title");
    }
    if (client.name.length < 10) {
      return client.name;
    }
    return client.name.substring(0, 10) + "...";
  };

  return (
    <div id="credentials-filter-mobile">
      <div className="filter-buttons">
        <div className="filter-buttons-gap"></div>
        <SelectButton
          selectdId="selectServices"
          text={formatService(selectedCategory)}
          isSmall={false}
          options={[
            { id: null, name: t("credentials_client_filter_all") },
            ...services,
          ]}
          setSelected={(service) => onFilterSelected(service)}
        />
        <div className="filter-buttons-gap"></div>
        <SelectButton
          selectdId="selectClients"
          text={formatClient(selectedClient)}
          isSmall={false}
          options={[
            { id: null, name: t("credentials_client_filter_all") },
            ...clients.sort((a, b) => a.name.localeCompare(b.name)),
          ]}
          setSelected={onClientSelected}
        />
        <div className="filter-buttons-gap"></div>
      </div>
    </div>
  );
};

export default CredentialsPage;
