import { useContext, useEffect, useState } from "react";
import { useDragScroll } from "../../shared/drag/use-drag-scroll";
import playVideoImage from "../../../assets/images/play_video.png";

import "./CredentialDetails.css";
import CredentialMedia from "../credential-media/CredentialMedia";
import { AppContext } from "../../../context/context-provider";
import { t } from "i18next";

const CredentialDetails = ({ credential, clientId, onDeselect }) => {
  const [horizontalImage, setHorizontalImage] = useState(null);
  const [otherMedia, setOtherMedia] = useState([]);

  const { getFullClient, getFullMedia } = useContext(AppContext);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMediaSiblings, setSelectedMediaSiblings] = useState(null);
  const client = getFullClient(clientId);

  useEffect(() => {
    if (credential.horizontalImage) {
      setHorizontalImage(getFullMedia(credential.horizontalImage));
    }

    let otherMedia = [];
    if (credential.otherMedia != null) {
      let mediaId = 0;
      credential.otherMedia.forEach((media) => {
        media.video = media.type == "video";
        media.imageData = getFullMedia(media.imageId);
        media.videoData = getFullMedia(media.id);

        media.formattedForDisplay = {
          id: mediaId++,
          url: media.video ? media.videoData.url : media.imageData.url,
          video: media.video,
          name: media.imageData.name,
        };

        otherMedia.push(media);
      });
    }
    setOtherMedia(otherMedia);
  }, [credential]);

  //From other media
  const handleOtherMediaSelected = (media, otherMedia) => {
    setSelectedMedia(media.formattedForDisplay);
    setSelectedMediaSiblings(otherMedia.map((m) => m.formattedForDisplay));
  };

  //From main media
  const handleMediaSelected = (media) => {
    setSelectedMedia(media);
    setSelectedMediaSiblings(null);
  };

  const handleMediaClose = () => {
    console.log("handleMediaClose");
    setSelectedMedia(null);
    setSelectedMediaSiblings(null);
  };

  return (
    <div className="credential-full-details">
      <div
        className="main-content"
        onClick={() =>
          handleMediaSelected(
            credential.mainVideo
              ? {
                  url: credential.mainVideo,
                  video: true,
                  name: credential.name,
                }
              : {
                  url: horizontalImage.url,
                  video: false,
                  name: credential.name,
                }
          )
        }
      >
        <img
          className="main-image"
          src={horizontalImage ? horizontalImage.url : null}
          alt={credential.name}
        />

        <img className="play-video" src={playVideoImage} alt="Collapse" />
        <CredentialFloatingCollapseButton onCollapseClick={onDeselect} />
      </div>
      <CreadentialInfo credential={credential} />
      <CredentialHorizontalLine />
      <CreadentiaMorelInfo credential={credential} client={client} />

      {otherMedia.length > 0 && (
        <CredentialSlider
          otherMedia={otherMedia}
          onMediaSelected={handleOtherMediaSelected}
        />
      )}

      <CredentialHorizontalLine />
      <CredentialCollapseButton onCollapseClick={onDeselect} />

      <CredentialMedia
        selectedMedia={selectedMedia}
        mediaSiblings={selectedMediaSiblings}
        onCloseClick={handleMediaClose}
      />
    </div>
  );
};

const CredentialSlider = ({ otherMedia, onMediaSelected }) => {
  const [ref, current] = useDragScroll();

  const handleScrollLeft = () => {
    const container = current();
    if (container) {
      container.scrollLeft -= container.clientWidth;
    }
  };

  const handleScrollRight = () => {
    const container = current();
    if (container) {
      container.scrollLeft += container.clientWidth;
    }
  };

  return (
    <>
      <div className="container">
        <div className="credential-details-moreinfo slider-info">
          <p className="title">{t("credentials_extra_content_title")}</p>
        </div>
      </div>
      <div className="slider-content container">
        <div
          className="slider-credential-scroll-left"
          onClick={handleScrollLeft}
        >
          <img
            className="scroll-image"
            src="/assets/images/collapse.svg"
            alt="Collapse"
          />
        </div>
        <div
          className="slider-credential-scroll-right"
          onClick={handleScrollRight}
        >
          <img
            className="scroll-image"
            src="/assets/images/collapse.svg"
            alt="Collapse"
          />
        </div>

        <div className="slider-content-scrollable container" ref={ref}>
          {otherMedia != null &&
            otherMedia.map((galleryEntry) => (
              <div className="image-container" key={galleryEntry.imageData.id}>
                <div
                  className="slider-content-child"
                  onClick={() => onMediaSelected(galleryEntry, otherMedia)}
                >
                  <img
                    src={galleryEntry.imageData.url}
                    alt={galleryEntry.imageData.name}
                    draggable="false"
                  />
                  <p>{galleryEntry.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const CreadentialInfo = ({ credential }) => {
  const projectDescriptionList = credential.projectDescription.split("\n");

  return (
    <div className="credential-details-info container">
      <p className="credential-details-name">{credential.name}</p>

      {projectDescriptionList.map((description) => (
        <p className="credential-details-description" key={description}>
          {description}
        </p>
      ))}
    </div>
  );
};

const CreadentiaMorelInfo = ({ credential, client }) => {
  const { agencyName, producerName, serviceDescription, references } =
    credential;

  const serviceDescriptionList =
    serviceDescription.indexOf("\\n") != -1
      ? serviceDescription.split("\\n")
      : serviceDescription.split("\n");

  return (
    <div className="credential-details-moreinfo container">
      <div className="moreinfo-block-special">
        <div className="moreinfo-block">
          <p className="title">{t("credentials_client_title")}</p>
          <p className="text">{client?.name || ""}</p>
          <div className="image-container">
            {client?.image?.url && (
              <img src={client.image.url} alt={client?.name || ""} />
            )}
          </div>
        </div>

        {agencyName && (
          <div className="moreinfo-block">
            <p className="title">{t("credentials_agency_title")}</p>
            <p className="text">{agencyName}</p>
          </div>
        )}

        {producerName && (
          <div className="moreinfo-block">
            <p className="title">{t("credentials_producer_title")}</p>
            <p className="text">{producerName}</p>
          </div>
        )}
      </div>

      <div className="moreinfo-block services">
        <p className="title">{t("credentials_services_title")}</p>
        {serviceDescriptionList.map((serviceDescription) => (
          <p key={serviceDescription}>{serviceDescription}</p>
        ))}
      </div>

      {references != null && references.length > 0 && (
        <div className="moreinfo-block references">
          <p className="title">{t("credentials_references_title")}</p>
          <div className="reference-container">
            {references.map((reference) => (
              <a
                key={reference.name}
                className="reference-block"
                href={reference.url}
                target="_blanck"
              >
                {reference.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CredentialHorizontalLine = () => {
  return <div className="horizontal-line"></div>;
};

const CredentialCollapseButton = ({ onCollapseClick }) => {
  return (
    <img
      onClick={onCollapseClick}
      src="/assets/images/collapse.svg"
      alt="Collapse"
      className="collapse-button"
    />
  );
};

const CredentialFloatingCollapseButton = ({ onCollapseClick }) => {
  return (
    <img
      onClick={onCollapseClick}
      src="/assets/images/collapse.svg"
      alt="Collapse"
      className="collapse-button floating"
    />
  );
};

export default CredentialDetails;
