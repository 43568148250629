import "./LanguageSelector.css";
import { useTranslation } from "react-i18next";
import iconES from "../../../assets/images/flag_es.svg";
import iconEN from "../../../assets/images/flag_en.svg";
import { useContext } from "react";
import { AppContext } from "../../../context/context-provider";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useContext(AppContext);

  const handleChangeLanguage = (language) => {
    changeLanguage(language);
    window.location.reload();
  };

  const newLanguage = i18n.language.startsWith("es") ? "en" : "es";
  const text = t(`language_selector_${newLanguage}`);
  const icon = newLanguage === "es" ? iconES : iconEN;

  return (
    <div
      onClick={() => handleChangeLanguage(newLanguage)}
      className="language-selector"
    >
      <img src={icon} alt={`language_selector_${newLanguage}`} />
      <span>{text}</span>
    </div>
  );
};

export default LanguageSelector;
