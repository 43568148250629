const isDev = false;

const getAllData = async () => {
  const url = isDev
    ? "http://127.0.0.1:5001/neutr-admin/europe-west3/apiGetAll"
    : "https://apigetall-fhtptg3xia-ey.a.run.app";
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    const data = json;

    return data;
  } catch (error) {
    console.error(error.message);
  }
  return;
};

const saveForm = async (formData) => {
  const url = isDev
    ? "http://127.0.0.1:5001/neutr-admin/europe-west3/apiSaveForm"
    : "https://apisaveform-fhtptg3xia-ey.a.run.app";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name: formData.name || "",
        email: formData.email || "",
        service: formData.service || "",
        message: formData.message || "",
      }),
    });

    if (!response.ok) {
      return { status: "error", error: response.status };
    }
    return { status: "success" };
  } catch (error) {
    console.error(error.message);
  }
  return { status: "error", error: "" };
};

export { getAllData, saveForm, isDev }; // getClients, getCredentials, getMedias };
